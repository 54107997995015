.scroll-watcher {
  position: fixed;
  animation-timeline: scroll() !important;
  animation: scroll-watcher linear; 
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #2a2f6b;
  z-index: 1000;
  transform-origin: left;
  scale: 0 1;
}

@keyframes scroll-watcher {
  to {
    scale: 1 1;
  }
}
.footer-content{
  text-align: center;
  padding: 10px;
  margin: 0 !important;
}

.footer-content h2{
  color: #f72a2a;
  margin: 0;
}

.footer-content h1{
  margin: 0;
  font-size: larger;
}

.footer-content h4{
  margin: 0;
}