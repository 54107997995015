@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@900&family=Sumana:wght@700&display=swap");
.us {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.about {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  padding: 10px;
  margin-top: -100px;

}

.about h1 h2 {
  margin-bottom: 40px;
  text-align: center;
}
.us p{
  text-align: center;
  color: #000;
}

@media (max-width: 1000px) {
  .us {
    margin-top: 0;
    margin-bottom: 7%;

  }
  .us .person {
    margin-bottom: 40px;
  }
}