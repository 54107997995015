/* Start Global Rules */
* {
    box-sizing: border-box;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -5%;
  }
  
  .landing-page .content .container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    min-height: calc(100vh - 80px);
  }


  @media (max-width: 1000px) {
    .landing-page .content .info {
      text-align: center;
      margin-bottom: 15px ;
      width: 100%;
    }
  }
 h1 {
    color: #2a2f6b;
    font-size: 400%;
    font-weight: bolder;
  }
 .landing-page p {
    margin: 0;
    line-height: 2;
    font-size: 17px;
    color: #181818;
  }
  .landing-page .content .info button {
    border: 0;
    border-radius: 20px;
    padding: 12px 30px;
    margin-top: 30px;
    cursor: pointer;
    color: #FFF;
    background-color: #2a2f6b;
  }
  .landing-page .content .image img {
    max-width: 80%;
    max-height: fit-content;
    margin-left: 40%;
  }
  /* End Landing Page */

  @media (max-width: 1000px) {
    .landing-page .content .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin-left: auto;
      margin-top: 10%;
    }

    .landing-page .content .container img {
      max-width: 90%;
      max-height: fit-content;
      margin-left: 5%;
    }
  }

.courses {
  width: 80%;
  padding-top: 100px;
  margin: auto;
  text-align: center;
}

.courses h1 {
  font-size: 40px;
  font-weight: 600;
  color: #2a2f6b;
  padding: 10px;
}


@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100..800&display=swap');


.slideshow {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    margin-bottom: 50px;
  }
  .mySwiper {
    width: 100%; /* Adjust this */
    height: 50vh; /* Adjust this */
    z-index: 999;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .slideshow img {
    width: 80%;  /* Set the width */
    height: 800px; /* Set the height */
    object-fit: cover;
    border-radius: 20px;
    margin-right: 10%;
    margin-left: 10%;
    
  }
  @media (max-width: 1000px) {
    .slideshow img {
      width: 80%;  /* Set the width */
      height: 230px; /* Set the height */
      object-fit: cover;
      border-radius: 20px;
      margin-right: 10%;
      margin-left: 10%;
    }
  }
  .swiper-pagination-bullet-active {
    background: #007aff;
  }
  
  .swiper-button-next, .swiper-button-prev {
    color: #007aff;
  }

  .text-after-swiper {
    width: 80%;
    margin: 20px auto;
    text-align: center;
    font-family: "Anek Malayalam", sans-serif;

    
  }

